/**
 * @returns {object | undefined} when bound successfuly
 */
 export const getCefSharp = async (objectName) => {
    try {
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync(objectName, 'bound');
        return Promise.resolve(window[objectName]);
    } catch (error) {
        console.log(
        'CefSharpLaunch Error: (most likely not running withn RN->PL app)',
        error
        );
    }
    return Promise.resolve();
};
