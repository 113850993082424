import Widget from 'src/components/FullPatientData/PatientWidgetBody/Widget/Widget';
import Attachment from 'src/components/FullPatientData/PatientWidgetBody/AttachmentWidget/Attachment/Attachment';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPaperclip,
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileImage,
  faFileExcel,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { mapGetters, mapState } from 'vuex';
import { permissionTypes } from 'src/constants/permissions';
import { getCefSharpBoundAsync } from 'src/utils/cefSharp/getCefSharpBoundAsync';
import { widgets } from 'src/constants/widgets';

// icon of the widget header
library.add(faPaperclip);
library.add(faFileAlt);
library.add(faFilePdf);
library.add(faFileWord);
library.add(faFileImage);
library.add(faFileExcel);
library.add(faFileCsv);

export default {
  props: {
    loadedCallback: {
      type: Function,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
    Widget,
    Attachment,
  },
  computed: {
    ...mapGetters(['applicant', 'hasPermission']),
    ...mapState({
      attachments: state =>
        state.applicant.visits.data
          .map(visit => visit.data != null && visit.data.attachments)
          .flat(),
      attachmentsPreARB2089: state =>
        state.applicant.visits.data
          .map(
            visit =>
              visit.epcrJsonData != null && visit.epcrJsonData.attachments
          )
          .flat(),
      epcrAttachments: state =>
        state.applicant.visits.data
          .map(
            visit =>
              visit.epcrJsonData != null && visit.epcrJsonData.epcrAttachments
          )
          .flat(),
      loading: state => !state.applicant.visits.retrieved,
    }),
    // permission
    canView() {
      return this.hasPermission(permissionTypes.canViewApplicant);
    },
    canViewAttachments() {
      return this.hasPermission(permissionTypes.viewAttachments);
    },
    hasAttachments() {
      return !!this.attachments[0];
    },
    hasAttachmentsPreARB2089() {
      return !!this.attachmentsPreARB2089[0];
    },
    hasEpcrAttachments() {
      return !!this.epcrAttachments[0];
    },
    widgetType() {
      return widgets.attachments.type;
    },
  },
  watch: {
    // TODO: refactor this into the widget component,
    // when ALL widgets actually use that component, to be DRY
    loading: {
      immediate: true,
      handler(current, previous) {
        // make sure the DOM is updated before dispatching this action
        // because we need the widget to have its full dimensions after
        // loading the data and rendering them...
        this.$nextTick(function() {
          if (current !== previous) {
            this.loadedCallback(!current);
          }
        });
      },
    },
  },
  beforeDestroy() {
    this.loadedCallback(false);
  },
  methods: {
    getIconName(fileName) {
      if (fileName != null) {
        const fileExtension = fileName.slice(
          ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
        );
        switch (fileExtension.toLowerCase()) {
          case 'pdf':
            return 'file-pdf';
          case 'docx':
          case 'doc':
            return 'file-word';
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'bmp':
          case 'webp':
            return 'file-image';
          case 'xls':
          case 'xlsx':
          case 'xlsm':
            return 'file-excel';
          case 'csv':
            return 'file-csv';
          default:
            return 'file-alt';
        }
      }
      return 'file-alt';
    },
    async launchAttachment(attachment) {
      try {
        (await getCefSharpBoundAsync())?.showMessage(attachment.attachment);
      } catch (error) {
        console.log('failed to show message', error);
      }
    },
    async launchEpcrAttachment(attachment) {
      try {
        (await getCefSharpBoundAsync())?.loadEpcrAttachment(
          attachment.tdate,
          attachment.job,
          attachment.filename
        );
      } catch (error) {
        console.log('failed to loadEpcrAttachment', error);
      }
    },
  },
};
